<template>
  <div v-if="evento">
    <div class="my-6">
      <h3 class="font-bold">Informações pós evento - {{ evento.nome }}</h3>
    </div>

    <div
      class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
      <ul class="flex flex-wrap -mb-px">
        <li class="me-2">
          <a href="#"
            :class="{ 'inline-block p-4 text-pink-800 border-b-2 border-pink-800 rounded-t-lg dark:text-pink-800 dark:border-pink-800': pagina === 0 }"
            class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            @click="setPagina(0)"> Leituras de Ingressos/Produtos </a>
        </li>
        <li class="me-2">
          <a href="#"
            :class="{ 'inline-block p-4 text-pink-800 border-b-2 border-pink-800 rounded-t-lg dark:text-pink-800 dark:border-pink-800': pagina === 1 }"
            class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            @click="setPagina(1)">Carga de Ingresso</a>
        </li>

        <li class="me-2">
          <a href="#"
            :class="{ 'inline-block p-4 text-pink-800 border-b-2 border-pink-800 rounded-t-lg dark:text-pink-800 dark:border-pink-800': pagina === 2 }"
            class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            @click="setPagina(2)">Lista de cargas</a>
        </li>
        <li class="me-2">
          <a href="#"
            :class="{ 'inline-block p-4 text-pink-800 border-b-2 border-pink-800 rounded-t-lg dark:text-pink-800 dark:border-pink-800': pagina === 3 }"
            class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            @click="setPagina(3)">Ingressos não integrado </a>
        </li>
      </ul>
    </div>

    <div v-if="pagina === 0">
      <div class="my-6">
        <h3 class="font-bold">Leituras Ingressos do Evento - {{ evento.nome }}</h3>
        <span class="text-sm text-gray-500">Aqui temos as opções de poder criar o Relatorio do evento ao clicar em
          <b>Relatorio</b> <br>
          e fazer o Download do Relatorio caso exista, clicando em <b>Baixar</b>.E mais abaixo temos as Informações <br>
          do que foi vendido e o que foi lido
        </span>
        <div class="flex flex-col lg:flex-row lg:justify-end mt-3">
          <Button @click="relatorio" color="primary" text="Gerar Relatorio Aplicativo" class="btn w-56 lg:w-44" />
          <Button @click="download" color="primary" text="Download Leituras Aplicativo" class="btn w-56 lg:w-44"
            v-if="evento.relatorio" />
          <Button @click="() => gerarRelatorioCatraca()" color="primary" text="Gerar Relatorio Catraca"
            class="btn w-56 lg:w-44" v-if="integracaoCatraca" />
          <Button @click="relatorioCatraca" color="primary" text="Download Relatorio Catraca" class="btn w-56 lg:w-44"
            v-if="integracaoCatraca" />
        </div>
      </div>
      <div class="card shadow-sm border p-8 bg-white  overflow-x-auto" v-if="produtosVenda.length > 0">
        <table class="min-w-full">
          <thead class="bg-white border-b">
            <tr>
              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">Produto</th>
              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">Quantidade Vendida</th>
              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">Quantidade Lida </th>
              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">Quantidade Não Lida </th>
              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">Porcentagem Lida</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(produtos) in produtosVenda" v-bind:key="produtos.id"
              class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100 ">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"> {{ produtos.nome }}</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"> {{ produtos.qtdTotal }} </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"> {{ produtos.qtdLido }} </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"> {{
                produtos.qtdNLido }} </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"> {{
                produtos.porcentagem }}% </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


    <div v-if="pagina === 1">
      <div class="my-6">
        <h3 class="font-bold">Carga de Ingressos para o Evento - {{ evento.nome }}</h3>
        <span class="text-sm text-gray-500">Aqui temos a opção de dar carga no Firebase e para o Catraca caso exista
          integração com ela, para isso, selecione os subtipos de
          produtos abaixo para dar a carga.
        </span>
      </div>
      <div class="card shadow-sm border p-8 bg-white">

        <div class="">
          <input type="checkbox" id="marcarTodos" v-model="marcarTodosCheck" @change="marcarTodos">
          <label for="marcarTodos" class="ml-2 mr-1 font-bold text-pink-900 cursor-pointer">Marcar todos</label>
        </div>

        <div v-for="(produtos) in produto" :key="produtos">
          <input type="checkbox" class="accent-pink-500 cursor-pointer" v-model="produtos.checked"
            @change="checkProduto(produtos.id)" :id="produtos.id + produtos.nome">
          <label class="mx-2 font-normal cursor-pointer" :for="produtos.id + produtos.nome">{{ produtos.nome }}</label>
          <div>
            <div v-for="(variacao) in produtos.variacao" :key="variacao" class="border-l border-gray-400 ml-1">
              <div class="ml-4">
                <input type="checkbox" class="accent-pink-500 cursor-pointer" v-model="variacao.showVariacao"
                  @change="checkVariacao(produtos.id, variacao.id)" :id="produtos.id + variacao.nome">
                <label class="mx-3 font-normal cursor-pointer" :for="produtos.id + variacao.nome">
                  {{ variacao.nome }}</label>
              </div>
              <div>
                <div class="border-l border-gray-400 flex ml-6">
                  <div class="ml-2">
                    <div v-for="(produto, index) in variacao.produtos" :key="index" class="m-1">
                      <input type="checkbox" class="accent-pink-500 cursor-pointer ml-1" v-model="produto.checkIngresso"
                        :id="produto.id + produto.detalhes" @change="checkIngressos(produtos.id, variacao.id)">
                      <label class="mx-3 font-normal cursor-pointer " :for="produto.id + produto.detalhes">
                        <fa class="text-gray-500 mr-2" :icon="`fa-solid fa-ticket`" size="lg" />
                        {{ produto.id }} - {{ produto.detalhes }}  - {{ produto.cod_produto }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="flex flex-col lg:flex-row lg:justify-start mt-10">
          <Button @click="darCarga" color="primary" text="Dar Carga Aplicativo" class="btn" />


          <Button @click="darCargaCatraca" color="primary" text="Dar Carga Catraca" class="btn"
            v-if="integracaoCatraca" />



          <Button @click="() => {
            modalConfirmarCarga.open(() => {
              limparLeiturasFirebase(), () => {
                return
              }
            })
          }" color="primary" text="Limpar Leituras Firebase" class="btn" />
        </div>


      </div>
    </div>

    <div v-if="pagina === 2">
      <div class="my-6">
        <h3 class="font-bold">Lista de cargas - {{ evento.nome }}</h3>
        <span class="text-sm text-gray-500">
          Abaixo, seguem todas as cargas de ingressos realizadas para o evento

        </span>
        <div>
          <Button color="primary" :text="`Alterar status das cargas `" id="statusCarga" @click="statusCarga"></Button>
        </div>
      </div>
      <div class="card shadow-sm border p-8 bg-white">
        <DataTable ref="dataTableRefCarga" :headers="headers" :data="[...lotesProcessos]" :options="options"
          color="primary" />
      </div>
    </div>


    <div v-else-if="pagina === 3">
      <div class="my-6">
        <h3 class="font-bold">Ingresso não integrados - {{ evento.nome }}</h3>
        <span class="text-sm text-gray-500">
          Abaixo, seguem os ingressos que após a venda, não conseguiram integrar com a catraca ou aplicativo
        </span>
      </div>
      <DataTable :headers="headersIngresso" :data="[...ingressos]" :options="optionsIngresso" color="primary" />
    </div>
  </div>
</template>

<script>
import Button from "../../components/Elements/Button.vue";
import { GET, PUT, POST } from "../../services/api";
import { inject, onMounted, ref, onBeforeUnmount, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import DataTable from "../../components/Elements/Datatable.vue";
import { useStore } from "vuex";
export default {
  name: "PosEvento",
  components: {
    DataTable,
    Button,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    formatDetalhes(detalhes) {
      return detalhes.split(';');
    },
  },
  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const loader = inject("loading");
    const alert = inject("alert");
    const modal = inject("modal");
    const modalLog = inject("modalLogs");
    const modalCarga = inject("modalCarga");
    const modalCargaCatraca = inject("modalCargaCatraca");
    const modalConfirmarCarga = inject("modalConfirmarCarga");
    const modalRelatorioCatraca = inject("modalRelatorioCatraca");
    const evento = ref()
    const leituras = ref()
    const subtipos = ref()
    const setor = ref([])
    const variacao = ref([])
    const produto = ref()
    const subtiposSelecionados = ref([])
    const produtosVenda = ref([]);
    const ingressos = ref([]);
    const integracaoCatraca = ref(false)
    const showCreateRelatorioCatraca = ref(true)
    const marcarTodosCheck = ref(true);
    const produtoSelecionadosId = ref([])
    const produtoSelecionadosObj = ref([])
    const pagina = ref(0)
    const store = useStore();
    const lotesProcessos = ref([])
    const dataTableRefCarga = ref(null);

    const statusCarga = () => {
      console.log(dataTableRefCarga.value.selectedItems)
      if (dataTableRefCarga.value.selectedItems.length === 0) {
        alert.open("Atenção!", "Selecione ao menos uma carga para altera o status", "warning");
        return;
      }
      store.commit('modalConfiguracao', {
        display: true,
        items: { items: dataTableRefCarga.value.selectedItems },
        callbackOK: () => { '' },
        carga:true
      })
     
    }

    const headers = [
      { key: "check", value: "check" },
      { key: "Id", value: "id" },
      { key: "Detalhes", value: "detalhes" },
      { key: "Tipo", value: "tipo_carga" },
      { key: "Status", value: "status" },
      { key: "Total de Ingressos", value: "total" },
      { key: "Ingressos Sincronizados", value: "total_sincronizado" },
      { key: "Ingressos com Erro", value: "total_erro" },
      { key: "Data Inicio", value: "data_inicio" },
      { key: "Data Fim", value: "data_fim" },
      { key: "Ações", value: "" },
    ];



    const porcentagem = (val1, val2) => {

      const x = parseInt(val1) / parseInt(val2);

      if (isNaN(x)) {
        return 0
      } else {
        const aux = x * 100;
        aux.toFixed(2)
        return aux.toFixed(2)
      }
    }

    const dataGraficoFunctionLegado = (data) => {

      try {
        const tabela = []

        for (const produto in data) {
          const schema = {
            qtdTotal: data[produto].quantidade,
            qtdLido: data[produto].lidos,
            qtdNLido: data[produto].quantidade - data[produto].lidos,
            porcentagem: 0,
            nome: produto
          }

          schema.porcentagem = porcentagem(schema.qtdLido, schema.qtdTotal)

          tabela.push(schema)
        }


        return tabela
      } catch (error) {

        return []
      }

    }

    const setSubTipo = () => {
      //subtiposSelecionados.value = subtipos.value.map(sub => sub.subtipo_id)
    }

    const getSelecionados = (id) => {
      produto.value.forEach(produto => {
        produto.variacao.forEach(variacao => {
          variacao.produtos.forEach(ingresso => {
            if (ingresso.checkIngresso === true) {
              if (id === true)
                produtoSelecionadosId.value.push(ingresso.id)
              else
                produtoSelecionadosObj.value.push(ingresso)
            }
          })
        });
      })
    }

    const checkMarcaTodos = () => {
      let cont = 0
      let tamanho = 0
      produto.value.forEach(produto => {
        produto.variacao.forEach(variacao => {
          variacao.produtos.forEach(ingresso => {
            tamanho++
            if (produto.checked === true && variacao.showVariacao === true && ingresso.checkIngresso === true) {
              cont++
            }
          })
        })
      })

      if (cont < tamanho)
        marcarTodosCheck.value = false
      else
        marcarTodosCheck.value = true
    }

    const marcarTodos = () => {
      if (marcarTodosCheck.value) {
        produto.value.forEach(produto => {
          produto.checked = true;
          produto.variacao.forEach(variacao => {
            variacao.showVariacao = true;
            variacao.produtos.forEach(ingresso => {
              ingresso.checkIngresso = true
            })
          });
        })
      } else {
        produto.value.forEach(produto => {
          produto.checked = false;
          produto.variacao.forEach(variacao => {
            variacao.showVariacao = false;
            variacao.produtos.forEach(ingresso => {
              ingresso.checkIngresso = false
            })
          });
        })
      }
    };

    const checkIngressos = (id, idVariacao) => {
      let cont = 0
      let contV = 0
      produto.value.forEach(produto => {
        if (produto.id === id) {
          produto.variacao.forEach(variacao => {
            variacao.produtos.forEach(ingresso => {
              if (produto.id === id && idVariacao === variacao.id && ingresso.checkIngresso === true)
                cont++
            });
          });
        }
      })
      produto.value.forEach(produto => {
        produto.variacao.forEach(variacao => {
          if (cont === 0 && produto.id === id && idVariacao === variacao.id) {
            variacao.showVariacao = false
          } else if (cont !== 0 && produto.id === id && idVariacao === variacao.id) {
            variacao.showVariacao = true
          }
          if (produto.id === id && variacao.showVariacao === true) {
            contV++
          }
        })
        if (contV === 0 && produto.id === id)
          produto.checked = false
        else if (contV !== 0 && produto.id === id)
          produto.checked = true
      })
      checkMarcaTodos()
    }

    const checkVariacao = (id, idVariacao) => {
      let cont = 0
      produto.value.forEach(produto => {
        produto.variacao.forEach(variacao => {
          if (produto.id === id && variacao.showVariacao === true) {
            cont++
            variacao.produtos.forEach(ingresso => {
              if (produto.id === id && idVariacao === variacao.id)
                ingresso.checkIngresso = true
            });
          } else {
            variacao.produtos.forEach(ingresso => {
              if (produto.id === id && idVariacao === variacao.id)
                ingresso.checkIngresso = false
            });
          }
        });
      })
      produto.value.forEach(produto => {
        if (cont === 0 && produto.id === id)
          produto.checked = false
        else if (cont !== 0 && produto.id === id)
          produto.checked = true
      })
      checkMarcaTodos()
    }

    const checkProduto = (id) => {
      produto.value.forEach(produto => {
        if (produto.id === id) {
          produto.variacao.forEach(variacao => {
            if (produto.id === id && variacao.showVariacao === false && produto.checked === true) {
              variacao.showVariacao = true
              variacao.produtos.forEach(ingresso => {
                ingresso.checkIngresso = true
              });
            } else {
              variacao.showVariacao = false
              variacao.produtos.forEach(ingresso => {
                ingresso.checkIngresso = false
              });
            }
          });
        }
      })
      checkMarcaTodos()
    }


    async function produtoSetorVariacao() {
      try {
        const list = await GET(`event/${route.params.id}/tickets_v2`);
        const produtos = list.data
        produtos.forEach(produto => {
          produto.checked = true;
          produto.variacao.forEach(variacao => {
            variacao.showVariacao = true;
            variacao.produtos.forEach(ingresso => {
              ingresso.checkIngresso = true
            });
          });
        });
        produto.value = produtos

      } catch (error) {
        console.log(error)
        alert.open("Atenção!", error, "danger");
      }
    }


    const dataGraficoFunction = (data) => {
      try {
        const auxData = [];

        for (const entries in data) {
          const schema = {
            nome: entries,
            ingressos: [],
            size: 0,
          };
          for (const produto of data[entries]) {
            schema.size += produto.size;

            for (const ingresso of produto.ingressos) {
              schema.ingressos.push(ingresso);
            }
          }

          auxData.push(schema);
        }

        const tabela = [];


        for (const produto of auxData) {
          const schema = {
            qtdTotal: produto.size,
            qtdLido: 0,
            qtdNLido: 0,
            porcentagem: 0,
            nome: produto.nome,
          };

          for (const leitura of produto.ingressos) {
            if (leitura.leituras.length && leitura.leituras.length > 0) {
              schema.qtdLido += 1;
            } else {
              schema.qtdNLido += 1;
            }
          }

          schema.porcentagem = porcentagem(schema.qtdLido, schema.qtdTotal);

          tabela.push(schema);
        }

        return tabela
      } catch (error) {
        return [];
      }
    };


    async function getEventoInfo() {
      try {

        const body = {
          filtro: [],
          "id_evento": route.params.id,
        };

        const response = await POST("/relatorios/portaria", body);

        const data = response.data.data;
        produtosVenda.value = dataGraficoFunction(data.dataGrafico)

      } catch (error) {
        console.log(error)
        alert.open("Atenção!", error, "danger");
      }
    }

    async function getRelatorioPortaria() {
      try {

        evento.value = { ...(await GET(`event/${route.params.id}`)) };

      } catch (error) {
        console.log(error)
        alert.open("Atenção!", error, "danger");
      }
    }

    async function getCatracaIntegracao() {
      try {
        const response = await GET(`catraca/${route.params.id}`);
        if (response.success) {
          integracaoCatraca.value = true
        }
      } catch (error) {
        console.log(error)
        alert.open("Atenção!", error, "danger");
      }
    }

    function delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function loadFilaProcesso() {
      try {
        const response = await POST(`gerenciar-lotes/filtro`, { "id_evento": parseFloat(route.params.id) });
        if (response.success) {
          lotesProcessos.value = response.data.map(e => {
            return {
              id: e.id,
              status: e.status,
              detalhes: e.detalhes,
              "tipo_carga": e.filaConfiguracao.type === 'catraca_dmpe' ? "DMEP" : "App",
              "data_inicio": e.data_inicio ? new Date(e.data_inicio).toLocaleString('pt-BR', { dateStyle: 'short', timeStyle: 'medium' }) : "",
              "data_fim": e.data_fim ? new Date(e.data_fim).toLocaleString('pt-BR', { dateStyle: 'short', timeStyle: 'medium' }) : "",
              total: e.filaProcesso.length,
              "total_sincronizado": e.filaProcesso.filter(e => e.status === 'concluido').length,
              "total_erro": e.filaProcesso.filter(e => e.status === 'erro').length,
            }
          }

          )
        }
      } catch (error) {
        console.log(error)
        alert.open("Atenção!", error, "danger");
      }
    }


    async function cancelarLoteCarga(param) {
      try {
        if (param === undefined) {
          alert.open("Atenção!", "Selecione um lote para cancelar", "warning");
          return
        }
        modal.open("Atenção",
          `A carga de identificador ${param.id} - ${param.detalhes} com ${param.total} ingressos será cancelada, tem certeza ?`,
          "warning",
          "Sim",
          async () => {
            loader.open();
            try {
              const response = await PUT(`gerenciar-lotes/${param.id}/cancelar`)
              if (response.success) {
                alert.open("Sucesso!", 'Carga foi cancelada', "success")
              }
              await loadFilaProcesso()
              loader.close();
            } catch (error) {
              loader.close();
              alert.open("Atenção!", error, "danger");
            }
          });
      } catch (error) {
        console.log(error)
        alert.open("Atenção!", error, "warning");
      }
    }

    async function refazerLoteCarga(param) {
      try {
        console.log(param)
        if (param === undefined) {
          alert.open("Atenção!", "Selecione um lote para cancelar", "warning");
          return
        }

        modal.open("Atenção",
          `A carga de identificador ${param.id} - ${param.detalhes} com ${param.total} ingressos será reiniciada, tem certeza ?`,

          "warning",
          "Sim",
          async () => {
            loader.open();
            try {
              const response = await PUT(`gerenciar-lotes/${param.id}/refazer`)

              if (response.success) {
                alert.open("Sucesso!", 'Carga foi reiniciada', "success")
              }
              await loadFilaProcesso()
              loader.close();
            } catch (error) {
              loader.close();
              alert.open("Atenção!", error, "danger");
            }
          });
      } catch (error) {
        console.log(error)
        alert.open("Atenção!", error, "warning");
      }
    }




    const download = () => {
      fetch(`https://santocartao-files.s3.amazonaws.com/relatorios/${evento.value.relatorio}`)
        .then(resp => resp.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          a.download = evento.value.relatorio
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => console.log(error));
    }
    const relatorio = async () => {
      if (evento.value.relatorio) {
        modal.open("Atenção",
          "Já existe um relatório, se continuar vai perder o antigo e criar um novo." +
          "Deseja continuar?",
          "warning",
          "Sim",
          async () => {
            try {
              await PUT(`relatorios/${route.params.id}`);
              evento.value = { ...(await GET(`event/${route.params.id}`)) };


              alert.open(
                "Sucesso!",
                `Relatorio criado com sucesso!`,
                "success"
              );
            } catch (error) {
              alert.open("Atenção!", error, "danger");
            }
          });
      } else {
        try {
          await PUT(`relatorios/${route.params.id}`)

          evento.value = { ...(await GET(`event/${route.params.id}`)) };

          alert.open(
            "Sucesso!",
            `Relatorio criado com sucesso!`,
            "success"
          );
        } catch (error) {
          alert.open("Atenção!", error, "danger");
        }
      }
    }

    const relatorioCatraca = async () => {
      try {
        loader.open();

        const relatorioCatraca = await GET(`relatorios/${route.params.id}/relatorio/catraca_leituras`);

        window.location.href = `${relatorioCatraca.data.Location}`;

        /*const response = await fetch(relatorioCatraca.data.Location);

        if (!response.ok) {
          throw new Error('Erro ao baixar o arquivo');
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = evento.value.relatorio || 'arquivo.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(relatorioCatraca.data.Location);*/

        loader.close();

        alert.open(
          "Sucesso!",
          `Relatorio criado com sucesso!`,
          "success"
        );
      } catch (error) {
        alert.open("Atenção!", error, "danger");
        loader.close();
      }

    }


    const darCarga = async () => {
      getSelecionados(true)
      if (produtoSelecionadosId.value.length != 0) {
        modal.open("Atenção",
          "Você tem certeza de dar carga no Aplicativo." +
          "Deseja continuar?",
          "warning",
          "Sim",
          async () => {
            loader.open();
            try {

              const response = await POST(`event/${route.params.id}/evento/carga_v2`, produtoSelecionadosId.value);

              getSelecionados(false)

              const auxResponse = response.data

              produtoSelecionadosObj.value.forEach(produto => {
                auxResponse.forEach(auxResponse => {
                  if (produto.id === parseInt(auxResponse.idProd)) {
                    auxResponse.nome = produto.detalhes
                  }
                })
              })

              produtoSelecionadosId.value = []
              produtoSelecionadosObj.value = []

              alert.open(
                "Sucesso!",
                `Carga realizada com sucesso!`,
                "success"
              );
              //remover os subtiopos selecionados apos dar carga

              modalCarga.open(auxResponse, ['nome', 'criado', 'atualizado', 'tamanho'])
              loader.close();
            } catch (error) {
              loader.close();
              alert.open("Atenção!", error, "danger");
            }
          });
      } else {
        loader.close();
        alert.open("Atenção!", "Selecione um produto para dar carga", "atention");
      }
    }

    const darCargaCatraca = async () => {
      getSelecionados(true)



      if (produtoSelecionadosId.value.length != 0) {
        modal.open("Atenção",
          "Você tem certeza de dar carga no catraca." +
          "Deseja continuar?",
          "warning",
          "Sim",
          async () => {
            loader.open();
            try {

              const response = await POST(`catraca/${route.params.id}/carga_v3`, produtoSelecionadosId.value);
              if (response && response.success) {

                if (response.dataNaoValidos.length > 0) {
                  modalCargaCatraca.open(response.dataNaoValidos, [])
                }
                marcarTodosCheck.value = true
              }

              alert.open(
                "Sucesso!",
                `Carga realizada com sucesso! Para verificar se deu certo aguarde na pagina de Logs`,
                "success"
              );

              produtoSelecionadosId.value = []
              produtoSelecionadosObj.value = []

              loader.close();
            } catch (error) {
              loader.close();
              alert.open("Atenção!", error, "danger");
            }
          });
      } else {
        loader.close();
        alert.open("Atenção!", "Selecione um Produto para dar carga", "atention");
      }

    }

    const qtdnLida = (val1, val2) => {
      const x = val1 - val2;

      return x


    }

    function gerarRelatorioCatraca() {
      modal.open("Atenção",
        "Ao solicitar um novo relatório, os registros salvos serão sobrepostos," +
        " deseja continuar?",
        "warning",
        "Sim",
        () => {
          modalRelatorioCatraca.open(route.params.id)
        })
    }

    async function limparLeiturasFirebase() {
      try {
        loader.open();

        const response = await POST(`firebase/${route.params.id}/limpar_leituras_v2`);

        getSelecionados(false)

        const auxResponse = response.data
        const produtosParaLimpeza = []

        produtoSelecionadosObj.value.forEach(produto => {
          auxResponse.forEach(auxResponse => {
            if (produto.id === parseInt(auxResponse.idProd)) {
              auxResponse.nome = produto.detalhes
              produtosParaLimpeza.push(auxResponse)
            }
          })
        })

        produtoSelecionadosId.value = []
        produtoSelecionadosObj.value = []

        alert.open(
          "Sucesso!",
          `Carga realizada com sucesso!`,
          "success"
        );
        modalCarga.open(produtosParaLimpeza, ['nome', 'criado', 'atualizado', 'tamanho'])
        loader.close();
      } catch (error) {
        console.log(error)
        loader.close();
        alert.open("Atenção!", error, "danger");
      }

    }

    const setPagina = (pag) => {
      pagina.value = pag

    }

    const options = [
      { icon: "info", path: '/eventos/posevento/carga-lote', title: "Detalhes", disable: (param) => param.total_erro === 0, "target_blank": true },
      //{ icon: "triangle-exclamation", action: (param) => mudarPoliticaEstoque(param), title: "Desabiliar controle de estoque" },
      { icon: "rotate-right", action: (param) => refazerLoteCarga(param), title: "Refazer" },
      { icon: "ban", action: (param) => cancelarLoteCarga(param), title: "Cancelar", disable: (param) => param.status === 'concluido' || param.status === 'cancelado' },

    ];

    async function getIngressos() {
      try {

        const response = await POST(`fila_processo/ingresso`, {
          "id_evento": route.params.id.toString(),
        });

        if (response.success) {
          ingressos.value = response.data.map(e => {
            return {
              ...e,
              tipo: e.filaConfig.type === 'catraca_dmpe' ? "DMEP" : "App",
              "error_limited": JSON.stringify(e.error),
              "content_limited": JSON.stringify(e.content),
              data: e.updated_at ? new Date(e.updated_at).toLocaleString('pt-BR') : "",
            }
          })
        }


      } catch (error) {
        console.error("Erro ao obter dados da carga:", error);
        throw error;
      }
    }

    const intervalId = setInterval(async () => {
      await loadFilaProcesso();
    }, 10000);

    const intervalIngressos = setInterval(async () => {
      await getIngressos();
    }, 10000);

    onBeforeUnmount(() => {
      clearInterval(intervalId);
    });

    const cleanupInterval = () => {
      clearInterval(intervalId);
      clearInterval(intervalIngressos);
    };

    onUnmounted(cleanupInterval);

    const headersIngresso = [
      { key: "Id", value: "id" },
      { key: "Tipo", value: "tipo" },
      { key: "Status", value: "status" },
      { key: "Evento", value: "id_evento" },
      { key: "Conteudo", value: "content_limited", limit: 50 },
      { key: "Error", value: "error_limited", limit: 50 },
      { key: "Realizado", value: "data" },
      { key: "Ações", value: "" },
    ];

    async function refazerProcesso(param) {
      try {
        if (param === undefined) {
          alert.open("Atenção!", "Selecione um processo para refazer", "warning");
          return
        }

        const response = await POST(`gerenciar-lotes/reprocessar_itens`, [
          {
            "id_evento": param.id_evento,
            "id_processo": param.id
          }
        ])

        if (response.success) {
          alert.open("Sucesso!", 'Processo foi reiniciada', "success")
        }

      } catch (error) {
        console.log(error)
        alert.open("Atenção!", error, "warning");
      }
    }


    async function visualizarErro(param) {
      try {
        if (!param) {
          alert.open("Atenção!", "Selecione um processo para visualizar", "warning");
          return
        }

        if (!param.error) {
          alert.open("Atenção!", "Não existe erro para ser visualizado", "warning");
          return
        }



        modalLog.open(JSON.stringify(param.error), '');

      } catch (error) {
        console.log(error)
        alert.open("Atenção!", error, "warning");
      }
    }

    async function visualizarConteudo(param) {
      try {
        if (!param) {
          alert.open("Atenção!", "Selecione um processo para visualizar", "warning");
          return
        }

        if (!param.content) {
          alert.open("Atenção!", "Não existe conteudo para ser visualizado", "warning");
          return
        }

        modalLog.open(JSON.stringify(param.content), '');
      } catch (error) {
        console.log(error)
        alert.open("Atenção!", error, "warning");
      }
    }

    const optionsIngresso = [
      { icon: "rotate-right", action: (param) => refazerProcesso(param), title: "Refazer" },
      { icon: "folder", action: (param) => visualizarConteudo(param), title: "Visualizar Conteúdo" },
      { icon: "eye", action: (param) => visualizarErro(param), title: "Visualizar Erro" },

    ];

    onMounted(async () => {

      try {
        delay(400).then(() => {
          loader.open();
        }),
          await Promise.all([
            getEventoInfo(),
            produtoSetorVariacao(),
            getRelatorioPortaria(),
            getCatracaIntegracao(),
            loadFilaProcesso(),
            getIngressos(),
            marcarTodosCheck.value = true
          ]);

      } catch (error) {
        console.log(error)
        alert.open("Atenção!", error, "danger");
      } finally {
        loader.close();
      }
    })


    return {
      evento,
      download,
      limparLeiturasFirebase,
      modalConfirmarCarga,
      relatorio,
      gerarRelatorioCatraca,
      leituras,
      produtosVenda,
      porcentagem,
      subtipos,
      route,
      modalRelatorioCatraca,
      subtiposSelecionados,
      darCarga,
      qtdnLida,
      relatorioCatraca,
      darCargaCatraca,
      integracaoCatraca,
      showCreateRelatorioCatraca,
      marcarTodos,
      marcarTodosCheck,
      produtoSetorVariacao,
      variacao,
      setor,
      produto,
      checkVariacao,
      checkProduto,
      checkIngressos,
      getSelecionados,
      produtoSelecionadosId,
      produtoSelecionadosObj,
      pagina,
      setPagina,
      lotesProcessos,
      headers,
      options,
      headersIngresso,
      optionsIngresso,
      ingressos,
      statusCarga,
      dataTableRefCarga
    };
  },
};
</script>

<style>
input[type="checkbox"] {
  appearance: none;
  height: 15px;
  width: 15px;
  background: #cecdcd;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

input[type="checkbox"]:checked {
  height: 15px;
  width: 15px;
  background: #7c1d55;
  border-radius: 4px;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Cria a classe com a animação */
.slide-down {
  animation: slideDown 1s ease-out forwards;
}
</style>